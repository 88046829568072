import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import EnquiryForm from '../../components/EnquiryForm';
import FeaturedSection from '../../components/FeaturedSection';
import {
  COLOR_GREEN,
  MAX_WIDTH,
  screen,
  COLOR_TEXT_GRAY,
  MAX_WIDTH_SITE,
} from '../../components/common/variables';

const Banner = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  @media ${screen.largeXL} {
    padding-top: 0;
  }

  .small-banner {
    max-width: 100%;
    @media ${screen.largeXL} {
      display: none;
    }
  }
  .large-banner {
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  padding: 40px 0 0 0;
  @media ${screen.largeXL} {
    padding: 90px 0 0 0;
  }
  .community-service {
    max-width: ${MAX_WIDTH};
    margin: 0 auto;
    padding: 0 35px;

    &__title {
      color: ${COLOR_GREEN};
      margin: 0;
      font-size: 1.5rem;
      font-weight: 400;
      @media ${screen.medium} {
        font-size: 1.8rem;
      }
      @media ${screen.largeXL} {
        font-size: 2.2rem;
      }
    }

    &__content {
      max-width: 760px;
      margin-top: 15px;
      @media ${screen.medium} {
        margin-top: 30px;
      }

      p {
        margin-bottom: 20px;
        font-size: 1rem;
        color: ${COLOR_TEXT_GRAY};
        @media ${screen.medium} {
          font-size: 1.1rem;
          margin-bottom: 30px;
        }
      }
    }

    &__img {
      max-width: 100%;
      margin: 40px 0;
      @media ${screen.largeXL} {
        max-width: 90%;
        margin: 80px 0;
      }
    }
  }
`;
const CommunityAssetAuditingPage = ({ data }) => {
  const { title, content, acfService } = data.content;

  return (
    <Layout>
      <SEO
        title={acfService.seo.pageTitle || title}
        description={data.content.acfService.seo.description}
      />
      {acfService.imageBanner && (
        <Banner>
          <GatsbyImage
            className="large-banner"
            alt={`${title} - Source Separation Systems`}
            image={
              acfService.imageBanner.localFile.childImageSharp.gatsbyImageData
            }
          />

          <div className="small-banner">
            <GatsbyImage
              alt={`${title} - Source Separation Systems`}
              image={
                data.smallScreenBanner.acfService.imageBanner.localFile
                  .childImageSharp.gatsbyImageData
              }
            />
          </div>
        </Banner>
      )}

      <Wrapper>
        <div className="community-service">
          <h1
            className="community-service__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div
            className="community-service__content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <StaticImage
            className="community-service__img"
            src="../../images/community-asset-auditing.png"
            alt="graph-info"
            placeholder="blurred"
          />
        </div>
      </Wrapper>
      <EnquiryForm text={data.content.acfService.enquiryFormDescription} />
      <FeaturedSection content={data.featuredContent.edges} />
    </Layout>
  );
};

export default CommunityAssetAuditingPage;

export const serviceData = graphql`
  {
    content: wpService(id: { eq: "cG9zdDo1MTg=" }) {
      title
      content
      acfService {
        seo {
          pageTitle
          description
        }
        enquiryFormDescription
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }

    smallScreenBanner: wpService(id: { eq: "cG9zdDo1MTg=" }) {
      acfService {
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 624)
            }
          }
        }
      }
    }

    featuredContent: allWpPost(
      limit: 3
      sort: { fields: modified, order: DESC }
      filter: {
        tags: { nodes: { elemMatch: { name: { eq: "Service Community" } } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          acfBlog {
            content
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 670, height: 490)
                }
              }
            }
          }
        }
      }
    }
  }
`;
